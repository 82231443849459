import React, { Component, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Icon from "@material-ui/core/Icon";
import "leaflet.awesome-markers";
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import DataTable from "../../components/DataTable";
import JSONTree from "../../components/JSONTree";
import theme from "../../theme";
import FilterWirelessLogger from "./FilterWirelessLogger";
import { Dictionary } from "../../classes/Dictionary";
import Fields from "./WirelessLoggerFieldset";
import { CopyHelper, DateHelper, ExpandRows, TableRowToggleEvent ,ConvertDLSettings} from "../../classes/Helper";
import HandyRustyStore from "../../stores/HandyRustyStore";
import SessionStore from "../../stores/SessionStore";
import moment from "moment";
import dispatcher from "../../dispatcher";
import history from "../../history";
import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";
import { errorHandler } from "../../stores/helpers";






const FileDownload = require('downloadjs');
function processRowData(data) {

  if (data.PhyPayloadJson && data.PhyPayloadJson.length > 0) {
    data.phyStr = data.PhyPayloadJson
    data.PhyPayloadJson = JSON.parse(data.PhyPayloadJson)
  }

  if (data.rxInfo) {
    
    data.RxInfo = JSON.parse(JSON.stringify(data.rxInfo))
  }

  if (data.txInfo && data.txInfo.length > 0) {
    data.txInfoStr =  data.txInfo
    data.txInfo = JSON.parse(data.txInfo);
  }


  data.fPort = '';
  if (data.PhyPayloadJson && data.PhyPayloadJson.macPayload && data.PhyPayloadJson.macPayload.fPort) {
    data.fPort = data.PhyPayloadJson.macPayload.fPort
  }

  if (data.PhyPayloadJson && data.PhyPayloadJson.macPayload && data.PhyPayloadJson.macPayload.fhdr) {
    if (data.PhyPayloadJson.macPayload.fhdr.fCtrl) {
      data.fCtrl = data.PhyPayloadJson.macPayload.fhdr.fCtrl
    }
    if (data.PhyPayloadJson.macPayload.fhdr.fOpts) {
      data.macCommands = data.PhyPayloadJson.macPayload.fhdr.fOpts
    }
    if (data.PhyPayloadJson.macPayload.frmPayload&&data.PhyPayloadJson.macPayload.frmPayload.length>0&&data.PhyPayloadJson.macPayload.frmPayload[0].cid) {
      let arr = data.PhyPayloadJson.macPayload.frmPayload
      if (data.macCommands){
        data.macCommands = [...data.macCommands,...arr]
      }else{
        data.macCommands = arr
      }
     
    }

    
   
  }
  if (data.PhyPayloadJson && data.PhyPayloadJson.macPayload && data.PhyPayloadJson.macPayload.cFlist){
    let arr = [
      {
        cid:"",
        payload:{
          homeNetID:data.PhyPayloadJson.macPayload.homeNetID,
          devAddr:data.PhyPayloadJson.macPayload.devAddr,
          rxDelay:  data.PhyPayloadJson.macPayload.rxDelay,
          RX1DROffset:ConvertDLSettings(4,8, data.PhyPayloadJson.macPayload.dlSettings),
          RX2DataRate:ConvertDLSettings(0,4, data.PhyPayloadJson.macPayload.dlSettings),
          Channels: data.PhyPayloadJson.macPayload.cFlist.payload.Channels
        }
      }
    ]
    if (data.macCommands){
      data.macCommands = [...data.macCommands,...arr]
    }else{
      data.macCommands = arr
    }
  }

  if(data.macCommands&&data.macCommands.length>0){
    data.macCommands.map((el)=>{
      if(el.payload&&el.payload.dlSettings){
        el.payload.RX1DROffset= ConvertDLSettings(4,8, el.payload.dlSettings)
        el.payload.RX2DataRate=ConvertDLSettings(0,4, el.payload.dlSettings)
        el.payload.dlSettings = undefined
      }
      
      return el
    })
  }

  if (!data.mic && data.PhyPayloadJson) {
    data.mic = data.PhyPayloadJson.mic
  }

  if (data.esp) {
    data.esp = reduceEspString(data.esp)
  }

  return data;
}

function calcESP(rssi, snr) {
  const a = 10 * Math.log10(1 + Math.pow(10, 0.1 * snr))
  let esp = rssi + snr - a

  return reduceEspString(esp)
}

function reduceEspString(esp) {
  if (!esp) return 0;
  let tmp = esp.toString().split('.');
  if (tmp.length > 1) {
    tmp[1] = tmp[1].substring(0, 3)
    esp = tmp.join('.');
  }

  return esp;
}

function getTimestamp(rx, utc) {

  if (rx.fine_timestamp) {
    return rx.fine_timestamp.replace('Z', '').replace('T', ' ') + ' (FTS)'
  }

  if (rx.gps_timestamp) {
    return rx.gps_timestamp.replace('Z', '').replace('T', ' ')
  }

  if (utc) {
    return new DateHelper().parseDate(utc).setMask('y-m-d h:i:s.ms').getUTCDate() + ' (by server)'
  }

  return ''
}

function GateWaysTable(props) {
  if (!props.tableRxInfo || props.tableRxInfo.length === 0) return null;

  return props.tableRxInfo.map((rx, c) => {
    return (
      <TableRow hover key={c}>
          <TableCell className={'cell pd'}>{rx.gateway_id}</TableCell>
          <TableCell className={'cell pd'}>{calcESP(rx.rssi, rx.lora_snr)}</TableCell>
          <TableCell className={'cell pd'}>{rx.rssi}</TableCell>
          <TableCell className={'cell pd'}>{rx.lora_snr}</TableCell>
      <TableCell className={'cell pd'}>{getTimestamp(rx, props.dateTime)}</TableCell>
    </TableRow>
    )
  })
}

function makeMacTable(macs) {
  
  let checkPayload = (payload, value) => {
    if (value===''){
        return
    }
    let typeOf = typeof value
    if (typeOf === 'number' || typeOf === 'boolean' || typeOf === 'string') {
      let val
      if (typeOf === 'boolean') {
        val = (value) ? 1 : 0
      } else {
        val = value
      }

      return (
        <TableRow key={"id1-"+Math.random()} hover style={{cursor: 'pointer'}}>
        <TableCell className={'cell pd'} style={{paddingLeft: '15px'}}>{payload}</TableCell>
        <TableCell className={'cell pd'}> {val} </TableCell>
      </TableRow>)

    } else if (typeOf === 'object') {
      if (Array.isArray(value)) {
        return (
          <TableRow key={"id2-"+Math.random()} hover style={{cursor: 'pointer'}}>
          <TableCell className={'cell pd'} style={{paddingLeft: '15px'}}>{payload}</TableCell>
          {payload==="chMask"&&<TableCell className={'cell pd'}> [{value.map((v, i) => {
            return (v) ? 1 : 0
          }).toString()}]
          </TableCell>}
          {payload==="Channels"&&<TableCell className={'cell pd'}> {value.map((v, i) => {
            return <p style={{margin:0}}>{i}: {v}</p>
          })}
          </TableCell>}
        </TableRow>)
      } else {
        return Object.keys(value).map((v, i) => {
          return (
            <TableRow key={"id3-"+i} hover style={{cursor: 'pointer'}}>
              <TableCell className={'cell pd'} style={{paddingLeft: '15px'}}>{v}</TableCell>
              <TableCell className={'cell pd'}> {value[v]}</TableCell>
            </TableRow>
          )
        })
      }
    }
  }

  return (
    <>
      <TableRow   key={"id3-"+Math.random()} hover style={{cursor: 'pointer'}}>
        <TableCell colSpan={2} />
      </TableRow>

      {macs.map((mac, i) => {
        return (
          <>
            <TableRow hover style={{cursor: 'pointer'}}>
              <TableCell className={'cell pd'}>
                  <span>
                      <b>MACcommand:</b>
                  </span>
              </TableCell>
                <TableCell className={'cell pd'}>{mac.cid.replace('Req', '').replace('Ans', '')}
                </TableCell>
            </TableRow>

            {mac.payload && Object.keys(mac.payload).map((payload, c) => {
              return checkPayload(payload, mac.payload[payload])
            })}
            <TableRow hover style={{cursor: 'pointer'}}>
            <TableCell colSpan={2} />
            </TableRow>
          </>
        )
      })}
    </>
  )
}

function LoggerRow(props) {
  let data = props.data

  if (!data) {
    return null;
  }

  const id = "J" + Math.ceil(Math.random() * 10000000000000000);

  data = processRowData(data)
  let display;
  if (props.expandAll) {
    display = 'table-row'
  } else {
    display = 'none'
  }

  return (
    <>
      <TableRow
        hover
        style={{cursor: 'pointer'}}>
        <TableCell
          id={id} className={'cell '}
          onClick={props.handleToggleClick}>
          <Icon
            style={{fontSize: 15, verticalAlign: 'middle'}}
            className="fa fa-plus-circle"/>
        </TableCell>

        {props.fields && props.fields.map((field, i) => (
        <TableCell key={i}
                   className={'cell pd'}
                   style={(field.cell.style) ? field.cell.style(data) : undefined}>
          {field.cell.render(data)}
        </TableCell>))}
      </TableRow>
      <TableRow className={id}
                id={'extended'}
                style={{display: display}}>
        <TableCell colSpan={props.fields.length}>
          <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
            <div style={{width: 'max-content', display: 'flex', flexDirection: 'column'}}>
              <Table>
                <TableBody>
                  {data.mType &&
                    <TableRow hover style={{cursor: 'pointer'}}>
                      <TableCell className={'cell pd'}>
                        <span>
                          <b>MType:</b>
                        </span>
                      </TableCell>
                      <TableCell
                        className={'cell pd'}>
                        <span>{data.mType} </span>
                      </TableCell>
                    </TableRow>
                  }
                  <TableRow hover style={{cursor: 'pointer'}}>
                      <TableCell className={'cell pd'}>
                        <span>
                          <b>Info:</b>
                        </span>
                      </TableCell>
                      <TableCell
                        className={'cell pd'}>
                       {data && data.frmPayload && <span> Data size (bytes): {data.frmPayload.length / 2}, </span>}
                       {(data.airtime || data.airtime !== 0) && <span>Airtime (s): {data.airtime}, </span>}
                       {(data.late !== undefined) &&  <span>Late: {data.late} </span>}
                      </TableCell>
                    </TableRow>
                  {data.fCtrl &&
                    <TableRow hover
                              style={{cursor: 'pointer'}}>
                      <TableCell className={'cell pd'}>
                        <span>
                          <b>FCtrl:</b>
                        </span>
                      </TableCell>
                      <TableCell
                        className={'cell pd'}>
                        <span>
                          {Object.keys(data.fCtrl).map((i, c) => {
                          let val = data.fCtrl[i];
                          if (val === true) {
                            val = 1
                          }
                          if (val === false) {
                            val = 0
                          }
                          return i.replace('adr', 'ADR') + ':' + val + ((c === Object.keys(data.fCtrl).length - 1) ? '' : ', ')
                        })}
                         
                        </span>
                      </TableCell>
                    </TableRow>
                  }

                  {data && data.macCommands && makeMacTable(data.macCommands)}

                  {data && data.frmPayload &&
                    <TableRow hover
                              style={{cursor: 'pointer'}}>
                      <TableCell className={'cell pd'}>
                        <span>
                          <b>Data (hex):</b>
                        </span>
                      </TableCell>
                      <TableCell
                        className={'cell pd'}>
                      <div style={{overflowWrap: 'anywhere', maxWidth: '565px'}}>
                        <span style={{whiteSpace: 'pre-wrap'}}
                              onDoubleClick={CopyHelper}>
                          {data.frmPayload}
                        </span>
                      </div>
                    </TableCell>
                    </TableRow>
                  }

               

              

                  {data && data.rxInfo.length > 0 &&
                    <TableRow hover style={{cursor: 'pointer'}}>
                      <TableCell className={'cell pd'}>
                        <span>
                          <b>GWs Table with rows:</b>
                        </span>
                      </TableCell>
                      <TableCell className={'cell'}>
                        <div style={{width: 'max-content'}}>
                          <Table style={{border: '1px solid #ccc'}}>
                            <TableHead>
                              <TableRow>
                                <TableCell className={'cell pd after'}>GW ID</TableCell>
                                <TableCell className={'cell pd after'}>ESP</TableCell>
                                <TableCell className={'cell pd after'}>RSSI</TableCell>
                                <TableCell className={'cell pd after'}>SNR</TableCell>
                                <TableCell className={'cell pd after'}>TimeStamp UTC</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <GateWaysTable dateTime={data.dateTime}
                                             tableRxInfo={data.rxInfo}/>
                            </TableBody>
                          </Table>
                        </div>
                      </TableCell>
                    </TableRow>
                  }

                  {data && data.txInfo && data.txInfo.frequency &&
                    <TableRow hover style={{cursor: 'pointer'}}>
                      <TableCell className={'cell pd'}>
                        <span>
                          <b>Frequency (MHz):</b>
                        </span>
                      </TableCell>
                      <TableCell
                      className={'cell pd'}>
                        <span>{data.txInfo.frequency / 1e+6}</span>
                      </TableCell>
                    </TableRow>
                  }

                </TableBody>
              </Table>
            </div>

            <div style={{width: '100%', display: 'flex'}}>
              {data && data.rxInfo && data.rxInfo.length > 0 && <div style={{marginRight: '30px'}}>
                <JSONTree shouldExpandNode={() => {
                  return false
                }} data={{rxInfo: data.rxInfo}}/>
              </div>}
              {data && data.PhyPayloadJson && <div style={{marginRight: '30px'}}>
                <JSONTree shouldExpandNode={() => {
                  return false
                }} data={{PhyPayloadJson: JSON.parse(data.phyStr)}}/>
              </div>}
              {data && data.txInfo && <div style={{marginRight: '30px'}}>
                <JSONTree shouldExpandNode={() => {
                  return false
                }} data={{txInfo: JSON.parse(data.txInfoStr) }}/>
                </div>
              }
            </div>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}


const styles = {
  chart: {
    width: 380,
  },
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
  },
};

let canLoad = false;
class ListWirelessLoggerTable extends Component {
  constructor() {
    super();
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.onPaginationNextPrev = this.onPaginationNextPrev.bind(this);
    this.resetTotal = this.resetTotal.bind(this);
    this.expandAllchange = this.expandAllchange.bind(this);
    this.dict = new Dictionary('diagnostics-table-head');
    this.state = {
      page: 0,
      expandAll: false
     
    }
    this.action = null
    this.total = null
    this.firstLoad = true
    
  }
  onPaginationNextPrev(action){
    this.action = action
   
  }
  expandAllchange(ev) {
    ev.persist();

    this.setState({expandAll: !this.state.expandAll}, () => {
      let icon = ev.target
      let arr = ['fa-minus-circle', 'fa-plus-circle']
      if (this.state.expandAll) {
        arr.reverse()
        ExpandRows(true)
      } else {
        ExpandRows(false)
      }
      icon.className = icon.className.replace(...arr);
    })
  }


  getPage(limit, offset, callbackFunc) {
    let filter = Object.assign({},this.props.filter) ;
    let query_string = window.location.hash.split('?')[1]

    if(query_string){
      let searchParams = new URLSearchParams(query_string);

      if(searchParams.has("organizationId")){
        filter["organizationId"] = parseInt(searchParams.get("organizationId") )
      }

      if(searchParams.has("start")){
        filter["start"] = searchParams.get("start")
      }

      if(searchParams.has("end")){
        filter["end"] = searchParams.get("end")
     
      }

      if(filter['filters']===undefined){
        filter['filters'] = {}
      }

      if(searchParams.has("gw")){
        filter['filters']["gw"] = searchParams.get("gw")
      }

      if(searchParams.has("devEUI")){
        filter['filters']["devEUI"] = searchParams.get("devEUI")
        if(!SessionStore.isAdmin() && SessionStore.organizations.filter(el =>  el.isDeviceAdmin ).length > 1  ){
          SessionStore.globalSearch(filter['filters']["devEUI"] ,1,0,(resp)=>{ 
            if(resp.totalCount>0 && resp.result[0].organizationID!==SessionStore.getOrganizationID() ){
                errorHandler( {message : "Device is out of scope current organization or not found."} )
            }
           
          })
          
        }
      }

      if(searchParams.has("devAddr")){
        filter['filters']["devAddr"] = searchParams.get("devAddr")
      }
      if(searchParams.has("mType")){
        filter['filters']["mType"] = searchParams.get("mType")
      }
      if(searchParams.has("direction")){
        filter['filters']["direction"] = searchParams.get("direction")
      }
    }

    if (canLoad===true) {
      setTimeout(()=>{
        ExpandRows(this.state.expandAll, () => {
          canLoad = false

          let rowsPerPage = SessionStore.getRowsPerPage()

          if(this.data&&this.data.length>0){
    
            if(this.action && this.action==='forward'){
              // filter["end"] = moment(this.data[this.data.length-1].dateTime).toISOString() 
              limit = rowsPerPage 
              // offset = this.state.page * limit
            }
  
            if(this.action && this.action==='backward'){ 
              // filter["order"] = "ASC";
              // filter["reverse"] = true;
              // filter["start"] = moment(this.data[0].dateTime).toISOString()
              limit = rowsPerPage
            }  

            if(this.action && this.action==='first'){
              this.firstLoad = true;
            }
          }

          filter["getTotal"] = false;
          if(this.firstLoad){
              this.firstLoad = false;
              this.resetTotal();
              limit = rowsPerPage;
              filter["getTotal"] = true;
          }

          HandyRustyStore.getDeviceFrames(filter, limit, offset,(obj)=>{
            canLoad = true
            this.action  = null
            this.data = obj.result
            const devEUI = filter?.filters?.devEUI
            const devAddr = filter?.filters?.devAddr
            if (devEUI || devAddr)
            {
              let lastUplinkDateTime
              const result = obj.result.reverse().map((item, index) => {
              if (item.direction === "RX") {
                const dateTime = item.dateTime
                const el =  {...item, delta: lastUplinkDateTime ? moment(dateTime) - moment(lastUplinkDateTime) : null } 
                lastUplinkDateTime = dateTime
                return el
              }
              else return {...item, delta: null}
              }
              ).reverse()
              obj.result = result
            }

            if(this.total===null){
              this.total = obj.totalCount
            }
            obj.totalCount = this.total
            callbackFunc(obj)
          } )
        })
      },5)
 
    }
  }

  resetTotal(){
    this.total=null
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    let props = Object.entries(this.props)

    for (const [key, value] of props) {
      if (nextProps[key] !== value) {
        return true
      }
    }
    
    return false;
  }

  getRow(data, key) {
    return (
      <LoggerRow key={key}
                 expandAll={this.state.expandAll}
                 handleToggleClick={(ev) => {
        TableRowToggleEvent(ev, this.props.disableAutoUpdate)
      }} fields={this.props.fields} data={data}/>
    );
  }

  render() {
    return (
      <DataTable 
        ref={this.props.tableRef}
        changePageEvent={this.props.changePageEvent}
        fields={this.props.fields}
        paginationTools={['forward','backward','first','pageTotal','paginator']}
        total={this.state.total}
        maxpage={false}
        onPaginationNextPrev={this.onPaginationNextPrev}
        header={
         <TableRow className={'head-container'}>
           <TableCell className={'cell'}>
             <Icon
               onClick={this.expandAllchange}
               title={'Expand all'}
               style={{
                 fontSize: 15,
                 verticalAlign: 'middle',
                 cursor: 'pointer'
                }}
               className={"fa fa-plus-circle"}/>
           </TableCell>

           {this.props.fields  && this.props.fields.map((field, i) => {

             return (
               <TableCell style={{paddingBottom: '10px'}}
                          key={i}
                          className={'header-name pd after'}>
                 {field.head.render()}
               </TableCell>
             )})
          }
         </TableRow>
        }
        getPage={this.getPage}
        getRow={this.getRow}
      />
    );
  }
}

ListWirelessLoggerTable = withStyles(styles)(ListWirelessLoggerTable);

function SimpleDialog(props) {

  const [checkedFields, setCheckedFields] = useState([...props.selectedFields]);

  return (
    <Dialog onClose={() => {
      props.onClose(checkedFields)
    }} aria-labelledby="simple-dialog-title" open={props.open}>
      <DialogTitle id="simple-dialog-title">Select fields</DialogTitle>
      <Grid xs={12}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            item
      >
        {Fields && Fields.map((f, i) => {
          return (
            <div key={i}
                 style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      paddingLeft: '10px'
                  }}
                 className={'header-name'}>
              <span> {(f.head.selector)?f.head.selector():f.head.render()} </span>
              <Checkbox
                style={{marginLeft: 'auto'}}
                checked={(checkedFields.indexOf(f.dataName) > -1)}
                color="default"
                inputProps={{'aria-label': 'checkbox with default color'}}
                name={f.dataName}
                onChange={(event) => {
                  let checked = [...checkedFields];
                  let name = event.currentTarget.name;
                  let check = event.target.checked;

                  if (check) {
                    if (checked.indexOf(name) === -1) {
                      checked.push(name)
                      setCheckedFields(checked);
                    }
                  } else {
                    let index = checked.indexOf(name);

                    if (index > -1) {
                      checked.splice(index, 1);
                      setCheckedFields(checked);
                    }
                  }

                }}
              />
            </div>
          )
        })}
      </Grid>
    </Dialog>
  )
}
const defaultChecked =  ['direction','payload', 'dateTime', 'localTime','devAddr', 'devEUI', 'fPort', 'fCnt', 'nFCnt', 'rxRssi', 'rxSnr', 'esp', 'SF', 'channel', 'gw', 'GW Lat', 'GW Long', 'GW GWCnt', 'mic']

class Diagnostics extends Component {
  constructor() {
    super();
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.checkSearchQuery = this.checkSearchQuery.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.resetTable = this.resetTable.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.changePageEvent = this.changePageEvent.bind(this);
    this.disableAutoUpdate = this.disableAutoUpdate.bind(this);
    this.setLastMonthLabel = this.setLastMonthLabel.bind(this);

    this.TableRef = React.createRef();
    this.FilterRef = React.createRef();
    this.ListRef = React.createRef();

    this.onCSVData = this.onCSVData.bind(this);
    this.onCSVSocketClose = this.onCSVSocketClose.bind(this);


    let storageLoggerFields = localStorage.getItem('LoggerFields')
    let parsed = (storageLoggerFields)?JSON.parse(storageLoggerFields):null
    let checked = defaultChecked
    if (parsed && parsed.length>0){
      checked = parsed
    }

    this.state = {
      csvData: "",
      exportTotal: 0,
      exportPerc: 0,
      tab: 0,
      fields: Fields.filter((field)=>(checked.indexOf(field.dataName)>-1)),
      checkedFields: checked,
      openFieldsDialog: false,
      filterChangeTimeOut: undefined,
      page: SessionStore.getPage(),
      filter: {
        // "start": moment().subtract(1,'month').toISOString(),
        "offset": 0,
        "limit": SessionStore.getRowsPerPage(),
        "filters": {
          "direction": "",
          "devEUI": "",
          "devAddr": "",
          "gw": "",
          "mType": ""
        }
      }
    };


    this.timeout = null;

    if (SessionStore.user !== null) {
      canLoad = true;
      if (!SessionStore.isAdmin()) {
        let orgId = SessionStore.getOrganizationID() || SessionStore.organizations[0].organizationID
        if(!SessionStore.isAdmin()){
          this.state.filter.organizationId = orgId || undefined;
        }
        this.state.filter.organizationId = orgId
      }
    }

  }

  changePageEvent(){
    this.disableAutoUpdate()
  }

  handleFilterChange(data, timeout = 1500,devEUIChanged,value) {
    let filter = this.state.filter

    let state = {filter: {...filter, ...data}}
    
      let Obj = {}
      for(let i in state.filter){
        if(i==='offset'||i==='limit'||i==='getTotal'){
            continue
        }
       
        if(i==='filters'){
          for(let f in state.filter[i]){
            if(state.filter[i][f]!==null&&state.filter[i][f]!==""&&state.filter[i][f]!==undefined){
              Obj[f] = state.filter[i][f]
            }
           
          }
        }else{
         
            if(state.filter[i]!==""&&state.filter[i]!==null&&state.filter[i]!==undefined){
                Obj[i] = state.filter[i] 
            }
        }
      }

      const u = new URLSearchParams(Obj).toString();

      this.props.history.replace('?'+u)
      this.ListRef.current.resetTotal()
      
      this.setState(state,()=>{
        if(!devEUIChanged ){
          this.resetTable()
        }
        if(devEUIChanged && !value){
          this.resetTable()
        }
      })
    
  }

  componentDidMount() {
    this.setLastMonthLabel(); // ?

    if (SessionStore.user === null ) {
      if(!SessionStore.fetchingProfile && SessionStore.getToken() === null){
      SessionStore.fetchProfile(()=>{})
      }
     
      SessionStore.on("userGot", () => {
        const userStore = SessionStore.getUser();
      
       
        if (userStore === null || userStore === undefined) {
          canLoad = false;
        window.sessionStorage.setItem('prevPage', '/diagnostics')
         history.replace('/login');
        } else { 
          canLoad = true;
          let filter = this.state.filter
          if(filter.organizationId===undefined){
             let orgId = (SessionStore.getOrganizationID())?SessionStore.getOrganizationID():((SessionStore.organizations.length>0)?SessionStore.organizations[0].organizationID:undefined) 
            if(!SessionStore.isAdmin()){
              filter.organizationId =  orgId ;
            }
          }
         
          this.setState(filter,()=>{
            this.resetTable()
          })
        }
      })
    } else {

      if(SessionStore.getToken() !== null){
        canLoad = true
        this.forceUpdate()

      } else {
        canLoad = false;
        window.sessionStorage.setItem('prevPage', '/diagnostics')
       
        history.replace('/login');
      }
    }
   
  }

  checkSearchQuery(mode) {
    let search = window.location.hash.split('?')[1];
    let allowed = ['gw', 'devEUI']
    if (search) {
      let filter = {};
      search.split('&').forEach((el) => {
        let obj = el.split('=');
        if (allowed.indexOf(obj[0]) > -1) {
          filter[obj[0]] = obj[1];
        }
      })
      if (Object.keys(filter).length > 0) {
        if (mode && mode === 'filter') {
          this.FilterRef.current.setState({
            filter: {
              filters: filter
            }
          })
        } else {
          let state = this.state
          state.filter["filters"] = filter
          this.setState(state)
        }
      }
      if (this.props && this.props.history) {
        this.props.history.replace(this.props.history.location.pathname)
      }
    }
  }

  handleClickOpen() {
    this.setState({
      openFieldsDialog: true,
    });
  }

  handleClickClose(checkedFields) {
    let checked = (checkedFields.length>0)?checkedFields:defaultChecked;

    localStorage.setItem("LoggerFields", JSON.stringify(checked))
    this.setState({
      openFieldsDialog: false,
      checkedFields: checked,
      fields: Fields.filter((field) => (checked.indexOf(field.dataName) > -1)),
      limit: 'limit'
    });
  }

  updateTable() {
    if (this.TableRef.current.updateTable) {
      this.TableRef.current.updateTable()
    }
  }

  resetTable() {
    if (this.TableRef.current.resetTable) {
      this.ListRef.current.firstLoad = true
      this.ListRef.current.resetTotal()

      this.TableRef.current.resetTable()
    }
  }

  notify(msg, type = "success") {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: type,
        message: msg,
      },
    });
  }

  exportCSV(filter,size, cb) {
    if (size === 'all') {
      size = this.TableRef.current.getCount()
    }

    let fil = Object.assign({}, filter);
    fil.start = (fil.start === ''||fil.start === null||fil.start===undefined||fil.start==="undefined") ? '' : moment(fil.start).toISOString()
    fil.end = (fil.end === ''||fil.end === null||fil.end === undefined||fil.end === "undefined") ? '' : moment(fil.end).toISOString()   

    fil["getTotal"] = false

    if(fil.organizationId===undefined){ 
      if (!SessionStore.isAdmin()) {
        fil.organizationId = SessionStore.getOrganizationID() || SessionStore.organizations[0].organizationID
      }
    }

    HandyRustyStore.getDeviceFramesCSV(fil, size, 0, this.onCSVData, this.onCSVSocketClose, cb)
  }

  onCSVData(d) {
    let currentPercentage = 0;
  
    if (d.total != null) {
      this.total = d.total
      this.current = undefined
      this.setState({
        exportTotal: d.total,
      })
    }

    if(d.current){
      this.current = d.current
    }
    if (d.chunk != null) {
   
      let data = this.state.csvData;
      data = data + atob(d.chunk);
      
      if (this.state.exportTotal !== 0) {
       
        currentPercentage = Math.round(d.current * 100 / this.state.exportTotal);
      }

      this.setState({
        csvData: data,
        exportPerc: currentPercentage + '%',
      });
    }
  }

  onCSVSocketClose(cb) {
    let time = moment().format('DDMMYYYY_HHmm')
    if (this.state.csvData === '') {
      this.notify("Error: No data available!", "error");
      cb()

      return;
    }
    if(this.total!==this.current){
      errorHandler({message:`Error: Data is not complete (got ${this.current} bytes out of ${this.total})`})
      cb();
      return 
    }


    FileDownload(this.state.csvData, 'Diagnostics_' + time + '.csv', "text/csv")
    this.notify("All the data downloaded!");
   
    this.setState({
      csvData: "",
      exportPerc: 0
    }, () => {
      this.count = 0 ;
      this.total = 0 ;
      cb()
      
    });
  }

  disableAutoUpdate() {
    this.FilterRef.current.autoReloadOnChange({target: {value: ''}})
  }

  setLastMonthLabel(props){
    this.FilterRef.current.datePickerRef.current.setState({date:'Last month',selectedOption:'Month'  })
  }

  render() {

    return (
      <Grid container
            spacing={4}
            id={"diagnostics"}>
        <TitleBar>
        <OrganizationBreadcrumps globalFalse org={this.props.org}>
            <TitleBarTitle title="LoRaWAN Logger"/>
          </OrganizationBreadcrumps>
        </TitleBar>
        <Grid item xs={12} style={{paddingBottom: 0}}>
          <FilterWirelessLogger ref={this.FilterRef}
                                exportCSVPerc={this.state.exportPerc}
                                clickOpenDialog={this.handleClickOpen}
                                exportCSV={this.exportCSV}
                                resetTable={this.resetTable}
                                updateTable={this.updateTable}
                                handleChange={this.handleFilterChange}/>
        </Grid>
        <Grid item xs={12} style={{paddingTop: 0}}>
          <ListWirelessLoggerTable 
                                  
                                  ref={this.ListRef}
                                  fields={this.state.fields}
                                   limit={this.state.limit}
                                   filterRef={this.FilterRef}
                                   tableRef={this.TableRef}
                                   disableAutoUpdate={this.disableAutoUpdate}
                                   changePageEvent={this.changePageEvent}
                                   page={this.state.page}
                                   filter={this.state.filter}/>
        </Grid>
        <SimpleDialog selectedFields={this.state.checkedFields}
                      open={this.state.openFieldsDialog}
                      onClose={this.handleClickClose}/>
      </Grid>
    );
  }
}

export default withStyles(styles)(Diagnostics);
