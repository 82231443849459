import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from "@material-ui/core";

import Plus from "mdi-material-ui/Plus";
import Delete from "mdi-material-ui/Delete";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import InternalStore from "../../stores/InternalStore";
import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";
import TableCellLink from "../../components/TableCellLink";
import Check from "mdi-material-ui/Check";
import Close from "mdi-material-ui/Close";

const styles = {
  tableRow:{ backgroundColor: "#f0f0f0"},
}

class APIKeyRow extends Component {


  render() {
    let active = null;
    if (this.props.obj.isActive) {
      active = <Check />;
    } else {
      active = <Close />;
    }
    return(
      <TableRow
      className={!this.props.obj.isActive && this.props.classes.tableRow}
        key={this.props.obj.id}
        hover={this.props.obj.isActive}>
        <TableCellLink to={`/organizations/${this.props.match.params.organizationID}/api-keys/${this.props.obj.id}`}>{this.props.obj.id}</TableCellLink>
        <TableCell>{this.props.obj.name}</TableCell>
        <TableCell>{this.props.obj.description.slice(0,100) + ((this.props.obj.description.length>100)?' ... ':'')  }</TableCell>
        <TableCell>{active}</TableCell>
        <TableCell align="right">
          <IconButton className={'noUDpaddings'} aria-label="delete"><Delete onClick={this.props.onDelete} /></IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

APIKeyRow = withRouter(withStyles(styles)(APIKeyRow))


class ListOrganizationAPIKeys extends Component {
  constructor() {
    super();
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.TableRef = React.createRef()
  }
  onDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this api key?")) {
      InternalStore.deleteAPIKey(id, resp => {
        this.TableRef.current.updateTable()
      });
    }
  }
  getPage = (limit, offset, callbackFunc) => {
    InternalStore.listAPIKeys({
      organizationID: this.props.match.params.organizationID,
      limit: limit,
      offset: offset,
    }, callbackFunc);
  }

  getRow = (obj) => {
    const Delete = ()=>{
      this.onDelete(obj.id)
    }
    return(<APIKeyRow onDelete={Delete} obj={obj} />);
  }

  render() {
    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={
            <TitleBarButton
              label="Create"
              icon={<Plus />}
              to={`/organizations/${this.props.match.params.organizationID}/api-keys/create`}
            />
          }
        >
        <OrganizationBreadcrumps org={this.props.org}>
            <TitleBarTitle title="Organization API keys" />
        </OrganizationBreadcrumps>
        </TitleBar>
        <Grid item xs={12}>
          <DataTable
            ref={this.TableRef}
            header={
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Active</TableCell>
                <TableCell></TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default ListOrganizationAPIKeys;
