import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import KVForm from "../../components/KVForm";
import EUI64Field from "../../components/EUI64Field";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import DeviceProfileStore from "../../stores/DeviceProfileStore";
import ServiceProfileStore from "../../stores/ServiceProfileStore";
import RoutingProfileStore from "../../stores/RoutingProfileStore";
import LocationStore from "../../stores/LocationStore";
import theme from "../../theme";
import { Map, Marker } from 'react-leaflet';
import MapTileLayer from "../../components/MapTileLayer";
import DeviceStore from "../../stores/DeviceStore";
import SessionStore from "../../stores/SessionStore";


const styles = {
  formLabel: {
    fontSize: 12,
  },
  mapLabel: {
    marginBottom: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
  },
};

class DeviceFormApp extends FormComponent {
  constructor(props) {
    super(props);
    this.getDeviceProfileOption = this.getDeviceProfileOption.bind(this);
    this.getDeviceProfileOptions = this.getDeviceProfileOptions.bind(this);
    this.getRoutingProfileOption = this.getRoutingProfileOption.bind(this);
    this.getRoutingProfileOptions = this.getRoutingProfileOptions.bind(this);
    this.getServiceProfileOption = this.getServiceProfileOption.bind(this);
    this.getServiceProfileOptions = this.getServiceProfileOptions.bind(this);
    this.dpOnChange = this.dpOnChange.bind(this);
    this.spOnChange = this.spOnChange.bind(this);
    this.coordinatesHandleChange = this.coordinatesHandleChange.bind(this);
    this.setCurrentPosition = this.setCurrentPosition.bind(this);
    this.updateZoom = this.updateZoom.bind(this);
    this.updatePosition = this.updatePosition.bind(this);

    this.regExpLat = /^(\+|-)?(?:90(?:(?:\.0{1,4})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,4})?))$/;
    this.regExpLon = /^(\+|-)?(?:180(?:(?:\.0{1,4})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,4})?))$/;

    this.spRef = React.createRef()
    this.state = {
      errorDP:false,
      mapZoom: 15,
      tab: 0,
      variables: [],
      tags: [],
      networkServerID: null,
      object: {
        location: {
          latitude: '',
          longitude: '',
          altitude: ''
        }
      },
      errorLatitude: false,
      errorLongitude: false,
      errorDevEUIValue: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.setKVArrays(this.props.object || {});

    if (!this.props.update) {
      this.setCurrentPosition();
    }

  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    if (prevProps.object !== this.props.object) {
      this.setKVArrays(this.props.object || {});
    }
  }

  setCurrentPosition(e) {
    if (e !== undefined) {
      e.preventDefault();
    }

    LocationStore.getLocation(position => {
      let object = this.state.object;
      object.location = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        altitude: position.coords.altitude,
      }
      this.setState({
        object: object,
      });
    });
  }

  updatePosition() {
    const position = this.refs.marker.leafletElement.getLatLng();
    let object = this.state.object;
    let alt = (this.state.object.location && this.state.object.location.altitude) ? this.state.object.location.altitude : 0
    object.location = {
      latitude: position.lat,
      longitude: position.lng,
      altitude: alt,
    }
    this.setState({
      object: object,
    });
  }

  updateZoom(e) {
    this.setState({
      mapZoom: e.target.getZoom(),
    });
  }

  setKVArrays = (props) => {
    let variables = [];
    let tags = [];

    if (props.variables !== undefined) {
      for (let key in props.variables) {
        variables.push({key: key, value: props.variables[key]});
      }
    }

    if (props.tags !== undefined) {
      for (let key in props.tags) {
        tags.push({key: key, value: props.tags[key]});
      }
    }

    this.setState({
      variables: variables,
      tags: tags,
    });
  }

  getDeviceProfileOption(id, callbackFunc) {
    DeviceProfileStore.get(id, resp => {
      this.setState({
        networkServerID: resp.deviceProfile.networkServerID
      })
      callbackFunc({label: resp.deviceProfile.name, value: resp.deviceProfile.id});
    });
  }

  getDeviceProfileOptions(search, callbackFunc) {
    DeviceProfileStore.list({limit: 999, offset: 0}, resp => {
      const options = resp.result.map((dp, i) => {
        return {label: dp.name, value: dp.id}
      });
      callbackFunc(options);
    });
  }

  getRoutingProfileOption(id, callbackFunc) {
    RoutingProfileStore.get(id, resp => {
      callbackFunc({label: resp.routingProfile.name, value: resp.routingProfile.id});
    });
  }

  getRoutingProfileOptions(search, callbackFunc) {
    RoutingProfileStore.list(null,100, 0, this.props.match.params.organizationID, resp => {
      const options = resp.result.map((rp, i) => {
        return {label: rp.name, value: rp.id}
      });
      callbackFunc(options);
    });
  }

  getServiceProfileOption(id, callbackFunc) {
    ServiceProfileStore.get(id, resp => {
      callbackFunc({
        label: resp.serviceProfile.name,
        value: resp.serviceProfile.id,
        availableDevicesCount: resp.availableDevicesCount
      });
    });
  }

  getServiceProfileOptions(search, callbackFunc) {
    ServiceProfileStore.list(this.props.match.params.organizationID, this.state.networkServerID || 0, 999, 0, resp => {
      const options = resp.result.map((sp, i) => {
        return {label: sp.name, value: sp.id, availableDevicesCount: sp.availableDevicesCount}
      });
      callbackFunc(options);
    });
  }

  spOnChange(e){
    this.onChange(e);
    this.setState({errorDP:false});
  }

  dpOnChange(e) {
    let curNS =  this.state.networkServerID;
    this.onChange(e);
    DeviceProfileStore.get(e.target.value, resp => {
      let state = this.state;
      if(curNS !== resp.deviceProfile.networkServerID){
         state.object.serviceProfileID = null;
         state.errorDP = true
      }else{
        state.errorDP = false
      }   
     state.networkServerID = resp.deviceProfile.networkServerID
      this.setState(state);      
    });
  }

  onTabChange = (e, v) => {
    this.setState({
      tab: v,
    });
  }

  coordinatesHandleChange(e) {
    // const regExpLat = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,7})?))$/;
    // const regExpLon = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,7})?))$/;

    let value = e.target.value;
    if (e.target.value.includes(',')) {
      const valueArr = e.target.value.split(',');
      if (e.target.name === 'latitude') {
        value = valueArr[0] ? valueArr[0] : 0;
      } else if (e.target.name === 'longitude') {
        value = valueArr[1] ? valueArr[1] : 0;
      }
    }

    let state = this.state;
    if (!state.object.location) {
      state.object.location = {
        longitude: '',
        latitude: '',
        altitude: ''
      }
    }
    if (e.target.id === "location.latitude") {
      if (value === '') {
        state.object.location.latitude = 0;
        state.errorLatitude = false;
      } else {
        state.object.location.latitude = value;
        if (this.regExpLat.test(value)) {
          state.errorLatitude = false;
        } else {
          state.errorLatitude = true;
          state.object.location.latitude = value;
        }
      }
    }
    if (e.target.id === "location.longitude") {
      if (value === '') {
        state.object.location.longitude = 0;
        state.errorLongitude = false;
      } else {
        state.object.location.longitude = value;
        if (this.regExpLon.test(value)) {
          state.errorLongitude = false
        } else {
          state.errorLongitude = true;
          state.object.location.longitude = value;
        }
      }
    }
    if (e.target.id === "location.altitude") {
      if (value === '') {
        state.object.location.altitude = 0
      } else {
        state.object.location.altitude = value
      }
    }
    this.setState(state);
  }

  onChangeDeviceEUI = (e) => {
    const {value} = e.target;
    this.setState({errorDevEUIValue: null});

    if (value) {

      if (value.length!== 16){
        this.setState({errorDevEUIValue: "It must be 16 characters long"});
        return;
      }

      DeviceStore.get(value, resp => {
        if (resp&&resp.device){
          this.setState({errorDevEUIValue: "A device with the devEUI exists already"});
          return;
        }        
      }, false);      
    }

    let lookup = e.target.id.split(".");
    const field = lookup[lookup.length - 1];
    lookup.pop(); // remove last item

    let object = this.state.object;
    let obj = object;
    for (const f of lookup) {
      obj = obj[f];
    }

    if (e.target.type === "checkbox") {
      obj[field] = e.target.checked;
    } else if (e.target.type === "number") {
      obj[field] = parseInt(e.target.value, 10);
    } else {
      obj[field] = e.target.value;
    }

    this.setState({object: object})
  }

  render() {
    const {errorDevEUIValue} = this.state

    if (this.state.object === undefined) {
      return null;
    }
    const style = {
      height: 400,
    };

    let position = [];

    const latitude = this.state?.object?.location?.latitude
    const longitude = this.state?.object?.location?.longitude

    if (this.regExpLon.test(longitude) && this.regExpLat.test(latitude)) {
      position = [latitude, longitude];
    } else {
      position = [0, 0];
    }

    const variables = this.state.variables.map((obj, i) => <KVForm key={i} index={i} object={obj}
                                                                   onChange={this.onChangeKV("variables")}
                                                                   onDelete={this.onDeleteKV("variables")}/>);
    const tags = this.state.tags.map((obj, i) => <KVForm key={i} index={i} object={obj}
                                                         onChange={this.onChangeKV("tags")}
                                                         onDelete={this.onDeleteKV("tags")}/>);

    return (
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled || errorDevEUIValue || this.state.errorLatitude || this.state.errorLongitude}
      >
        <Tabs value={this.state.tab} onChange={this.onTabChange} indicatorColor="primary">
          <Tab label="General"/>
          <Tab label="Variables"/>
          <Tab label="Tags"/>
        </Tabs>
        
        {this.state.tab === 0 && <div>
          <TextField
            id="name"
            label="Device name"
            margin="normal"
            onChange={this.onChange}
            value={this.state.object.name || ""}
            fullWidth
            required
            disabled={this.props.disabled}
            error={!!this.props.errorName}
            helperText={this.props.errorName ? "A device with the name already exists. Choose another one." : "The name must be unique and can only letters, numbers and dashes are allowed."}
          />
          <TextField
            id="description"
            label="Device description"
            margin="normal"
            value={this.state.object.description || ""}
            onChange={this.onChange}
            fullWidth
            disabled={this.props.disabled}
          />
          {!this.props.update && <>
            <EUI64Field
              margin="normal"
              id="devEUI"
              label="Device EUI"
              onChange={this.onChangeDeviceEUI}
              value={this.state.object.devEUI || ""}
              fullWidth
              required
            />
            {errorDevEUIValue && (
              <FormHelperText
                error={true}
              >
                {errorDevEUIValue}
              </FormHelperText>
            )}
          </>
          }
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel}>Service-profile</FormLabel>
            <AutocompleteSelect
              id="serviceProfileID"
              label="Select service-profile"
              value={this.state.object.serviceProfileID || ""}
              onChange={this.spOnChange}
              getOption={this.getServiceProfileOption}
              getOptions={this.getServiceProfileOptions}
              margin="none"
              triggerReload={this.state.networkServerID}
              clearable={true}
              availableDeviceCount={true}
              disabled={!this.state.object.deviceProfileID ||( !SessionStore.isAdmin() && !SessionStore.isOrganizationDeviceAdmin(this.props.match.params.organizationID) && !SessionStore.isOrganizationAdmin(this.props.match.params.organizationID) )}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>Device-profile</FormLabel>
            <AutocompleteSelect
              required
              id="deviceProfileID"
              label="Device-profile"
              value={this.state.object.deviceProfileID}
              onChange={this.dpOnChange}
              getOption={this.getDeviceProfileOption}
              getOptions={this.getDeviceProfileOptions}
              disabled={this.props.disabled}
              error={this.state.errorDP}
              errorHelperText={(this.state.errorDP)?"The Device Profile belongs to another Frequency Plan! So you have to pick-up 'Service Profile' item from the same plan down below.":""}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>AS Routing profile</FormLabel>
            <AutocompleteSelect
              required
              id="routingProfileID"
              label="AS Routing Profile"
              value={this.state.object.routingProfileID}
              onChange={this.onChange}
              getOption={this.getRoutingProfileOption}
              getOptions={this.getRoutingProfileOptions}
              disabled={this.props.disabled}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormGroup>
              <FormControlLabel
                label="Device is disabled"
                control={
                  <Checkbox
                    id="isDisabled"
                    checked={!!this.state.object.isDisabled}
                    onChange={this.onChange}
                    color="primary"
                    disabled={this.props.disabled}
                  />
                }
              />
            </FormGroup>
            <FormHelperText>
              Network Server will ignore received uplink frames and join-requests from disabled devices.
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <FormGroup>
              <FormControlLabel
                label="Do not clear tx-queue after rejoin"
                control={
                  <Checkbox
                    id="keepQueue"
                    checked={!!this.state.object.keepQueue}
                    disabled={this.props.disabled}
                    onChange={this.onChange}
                    color="primary"
                  />
                }
              />
            </FormGroup>
            <FormHelperText>
              If checked: server won't flush the device queue in case if the device has been reJoined (OTAA) or reactivated (ABP)
            </FormHelperText>
          </FormControl>

          <TextField
            id="location.latitude"
            label="Device latitude (example - 59.9421)"
            margin="normal"
            type="text"
            name="latitude"
            error={this.state.errorLatitude}
            value={(this.state.object.location && this.state.object.location.latitude) ? this.state.object.location.latitude : ''}
            onChange={this.coordinatesHandleChange}
            fullWidth
            disabled={this.props.disabled}
          />
          <TextField
            id="location.longitude"
            label="Device longitude (example - 30.3133)"
            margin="normal"
            type="text"
            name="longitude"
            error={this.state.errorLongitude}
            value={(this.state.object.location && this.state.object.location.longitude) ? this.state.object.location.longitude : ''}
            onChange={this.coordinatesHandleChange}
            fullWidth
            disabled={this.props.disabled}
          />
          <TextField
            id="location.altitude"
            label="Device altitude (meters)"
            margin="normal"
            type="text"
            value={(this.state.object.location && this.state.object.location.altitude) ? this.state.object.location.altitude : ''}
            onChange={this.coordinatesHandleChange}
            fullWidth
            disabled={this.props.disabled}
          />
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.mapLabel}>Device location</FormLabel>
            <Map
              center={position}
              zoom={this.state.mapZoom}
              style={style}
              animate={true}
              scrollWheelZoom={false}
              onZoomend={this.updateZoom}
            >
              <MapTileLayer/>
              <Marker position={position} draggable={true} onDragend={this.updatePosition} ref="marker"/>
            </Map>
            <FormHelperText>
              Drag the marker to the location of the device.
            </FormHelperText>
          </FormControl>
        </div>}

        {this.state.tab === 1 && <div>
          <FormControl fullWidth margin="normal">
            <Typography variant="body1">
              Variables can be used to substitute placeholders in for example integrations, e.g. in case an integration
              requires the configuration of a device specific token.
            </Typography>
            {variables}
          </FormControl>
          <Button variant="outlined" onClick={this.addKV("variables")} disabled={this.props.disabled}>Add
            variable</Button>
        </div>}

        {this.state.tab === 2 && <div>
          <FormControl fullWidth margin="normal">
            <Typography variant="body1">
              Tags can be used as device filters and are exposed on events as additional meta-data for aggregation.
            </Typography>
            {tags}
          </FormControl>
          <Button variant="outlined" onClick={this.addKV("tags")} disabled={this.props.disabled}>Add tag</Button>
        </div>}
      </Form>
    );
  }
}

export default withStyles(styles)(DeviceFormApp);
