import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from '@material-ui/core/IconButton';

import Plus from "mdi-material-ui/Plus";
import Delete from "mdi-material-ui/Delete";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import InternalStore from "../../stores/InternalStore";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {withStyles} from "@material-ui/core/styles";
import TableCellLink from "../../components/TableCellLink";
import Check from "mdi-material-ui/Check";
import Close from "mdi-material-ui/Close";

const styles ={

  visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
  },
  tableRow:{ backgroundColor: "#f0f0f0"},
};

class APIKeyRow extends Component {
 

  render() {
    let active = null;
    if (this.props.obj.isActive) {
      active = <Check />;
    } else {
      active = <Close />;
    }
    return(
      <TableRow
      className={!this.props.obj.isActive && this.props.classes.tableRow}
        key={this.props.obj.id}
        hover={this.props.obj.isActive}
      >
        <TableCellLink to={`/api-keys/${this.props.obj.id}`}>{this.props.obj.id}</TableCellLink>
        <TableCell>{this.props.obj.name}</TableCell>
        <TableCell>{this.props.obj.description.slice(0,100) + ((this.props.obj.description.length>100)?' ... ':'')  }</TableCell>
        <TableCell>{active}</TableCell>
        <TableCell align="right">
          <IconButton className={'noUDpaddings'} aria-label="delete"><Delete onClick={this.props.onDelete} /></IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

APIKeyRow = withRouter(withStyles(styles)(APIKeyRow));


class ListAdminAPIKeys extends Component {
  constructor() {
    super();

    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.makeSortLabel = this.makeSortLabel.bind(this);
    this.makeCellSortProps = this.makeCellSortProps.bind(this);

    this.state = {
        orderBy:null,
        order:null
    }

    this.TableRef = React.createRef()
  }
  onDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this api key?")) {
      InternalStore.deleteAPIKey(id, resp => {
        this.TableRef.current.updateTable()
      });
    }
  }
  getPage = (limit, offset, callbackFunc) => {
    InternalStore.listAPIKeys({
      isAdmin: true,
      limit: limit,
      offset: offset,
      order: this.state.order,
      orderBy: this.state.orderBy
    }, callbackFunc);
  }
  makeSortLabel(keyName,viewName){
    return  <TableSortLabel
          data-key={keyName}
          active={this.state.orderBy === keyName}
          direction={this.state.orderBy === keyName ? this.state.order : 'asc'}
          onClick={this.changeSort}
      >
        {viewName}
          {this.state.orderBy === keyName ? (
              <span className={this.props.classes.visuallyHidden}>
                  {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
          ) : null}
      </TableSortLabel>
    }
    changeSort(event){
      let state = {
          orderBy:event.currentTarget.dataset.key,
          order:this.state.order
      }
  
      if(state.orderBy!==this.state.orderBy){
          state.order = null;
      }
  
      if(state.order === null){
          state.order = 'asc'
      }else if(state.order === 'asc'){
          state.order = 'desc'
      }else if(state.order === 'desc'){
          state.order = null
          state.orderBy = null
      }
  
      this.setState(state);
    }
  
  makeCellSortProps(keyName){
    return {
        key:keyName,
        align:'left',
        padding:'normal',
        sortDirection:this.state.orderBy === keyName ? this.state.order : false
    }
  }
  getRow = (obj) => {
    const Delete = ()=>{
      this.onDelete(obj.id)
    }
    return(<APIKeyRow onDelete={Delete} obj={obj} />);
  }

  render() {
    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={
            <TitleBarButton
              label="Create"
              icon={<Plus />}
              to={`/api-keys/create`}
            />
          }
        >
          <TitleBarTitle title="Global API keys" />
        </TitleBar>
        <Grid item xs={12}>
          <DataTable
          orderBy={this.state.orderBy}
          ref={this.TableRef}
            header={
              <TableRow>
                <TableCell {...this.makeCellSortProps('id')}>{this.makeSortLabel('id','ID')}</TableCell>
                <TableCell  {...this.makeCellSortProps('name')}>{this.makeSortLabel('name','Name')}</TableCell>
                <TableCell  {...this.makeCellSortProps('description')}>{this.makeSortLabel('description','Description')}</TableCell>
                <TableCell  {...this.makeCellSortProps('isActive')}>{this.makeSortLabel('isActive','Active')}</TableCell>
              
                <TableCell></TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ListAdminAPIKeys);
