import React, { Component } from "react";

import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";

import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import ExportStore from "../../stores/ExportStore";
import { DateHelper, getUuid, jsonToCsv } from "../../classes/Helper";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import OrganizationStore from "../../stores/OrganizationStore";
import moment from "moment";
import dispatcher from "../../dispatcher";
import { Fragment } from "react";
import NetworkServerStore from "../../stores/NetworkServerStore";
import { errorHandler } from "../../stores/helpers";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/ru";

const FileDownload = require('downloadjs');

const styles = {
    selectExport: {
      width: '300px',
      marginRight: '10px'
    },
    selectOrganization: {
      width: '300px',
    },
    selectNS:{
      width: '300px',
      marginTop:'30px'
    },
    selectDateTime: {
      width: '300px',
      marginTop:'30px',
  }, 
    exportFormContainer: {
      display: 'flex',
      paddingBottom: '30px'
    }
};

class Export extends Component {
    constructor() {
        super();
        this.exportSelectOnChange = this.exportSelectOnChange.bind(this);
        this.organizationSelectOnChange = this.organizationSelectOnChange.bind(this);
        this.exportFile = this.exportFile.bind(this);
        this.getOrganizationOption = this.getOrganizationOption.bind(this);
        this.getOrganizationOptions = this.getOrganizationOptions.bind(this);
        this.onCSVData = this.onCSVData.bind(this);
        this.onCSVSocketClose = this.onCSVSocketClose.bind(this);
        this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
        this.formIsValid = this.formIsValid.bind(this)
        this.onChangeFp = this.onChangeFp.bind(this);
        this.getNetworkServerOption = this.getNetworkServerOption.bind(this);
        this.getNetworkServerOptions = this.getNetworkServerOptions.bind(this);


        
        this.state = {
          organizationId: null,
            start: null,
            end: null,
            exportValue: '',
            organization: 'all',
            nsId:null,
            csvData : '',
            exportTotal : 0,
            exportPerc: 0,
            exportLoading: false,
        };

        this.count = 0
        this.total = 0
    }


    exportSelectOnChange(event){
      let state = this.state;
      if (event.target.value !== 'devices') {
        state.organization = 'all';
      }
      state.exportValue = event.target.value
      this.setState(state)
    }

    organizationSelectOnChange(event) {
      let state = this.state;
      state.organization = event.target.value
      this.setState(state)
    }

    notify(msg,type="success") {
      dispatcher.dispatch({
        type: "CREATE_NOTIFICATION",
        notification: {
          type: type,
          message: msg,
        },
      });
  }

  formIsValid() {
    switch (this.state.exportValue) {
      case "count_frames":
      const { organizationId, start, end } = this.state
          return (organizationId && start && end) ? true : false
        default:
          return true
  }
}

  exportFile() {
    this.setState({ exportLoading: true })
    const filter = {}
    switch (this.state.exportValue) {
      case "count_frames":
        filter.organizationId = this.state.organizationId
        if (this.state.start && this.state.end) {
          filter.start = this.state.start.toISOString()
          filter.end = this.state.end.toISOString()
        }
        ExportStore.getCountDeviceFrames(filter, (data, error)=> {
          if (!error)
          {
            const now = moment().format('DDMMYYYY_HHmm');
            FileDownload(jsonToCsv(data), `Export-${this.state.exportValue}_${now}.csv`, "text/csv");
          }
          this.setState({ exportLoading: false })
        })
        break
      default:
        if (this.state.organization && this.state.organization !== "all") {
          filter.orgId = this.state.organization
        }
  
        if (this.state.nsId) {
          filter.nsId = this.state.nsId
        }
        ExportStore.dataExportCSV(this.state.exportValue, filter, this.onCSVData, this.onCSVSocketClose, () => {
          this.setState({ exportLoading: false })
        })
    }
  }

    onCSVData(d) {
      let currentPercentage = 0;
      if (d.total != null) {
        this.total = d.total;
        this.count  = 0;
        this.setState({
          exportTotal: d.total,
        })
      }

      if (d.current != null) {
        this.count = d.current + 1;
      }

      if (d.chunk != null) {

        let data = this.state.csvData;
        data = data+atob(d.chunk);

        if (this.state.exportTotal !== 0) {
          currentPercentage = Math.round(this.count * 100 / this.state.exportTotal);
        }

        this.setState({
          csvData: data,
          exportPerc: currentPercentage,
        });
      }
  }

  handleDateTimeChange(params, time){
    let state = this.state;
    state[time] = params[1];

    if (!params || params[0] == null || params[0].toString() ==='Invalid Date') {
      if (state[time] == null ) {
        return;
      }
      state[time] = null
    } else {
      state[time] = new DateHelper().setDate(params[0]).toIsoString() || null;
    }
  }


  onCSVSocketClose(cb) {
    let time = moment().format('DDMMYYYY_HHmm');

    if (this.total === 0) {
        //this.notify("Error: No data available!", "error");
        cb();
        return;
    }

    if(this.count<this.total){
      errorHandler({message:`Error: Data is not complete (got ${this.count} row out of ${this.total})`})
      cb();
      return 
    }

    if(this.count>this.total){
      errorHandler({message:`Warning: recieved ${this.count} rows while expected value was ${this.total})`})
    }

    FileDownload(this.state.csvData, `Export-${this.state.exportValue}_${time}.csv`, "text/csv");
    this.notify("All the data downloaded!");
    this.setState({
        csvData: '',
        exportPerc: 0
    }, () => {
      this.count = 0 
      this.total = 0 
        cb();
    });

    
  }


  getOrganizationOption(id, callbackFunc) {
    if (id !== 'all') {
      OrganizationStore.get(id, resp => {
        callbackFunc({label: resp.organization.name, value: resp.organization.id});
      });
    }
  }

  getOrganizationOptions(search, callbackFunc) {
    OrganizationStore.list(search, 999, 0, resp => {
      const options = resp.result.map((o, i) => {return {label: o.name, value: o.id}});
      callbackFunc(options, resp.totalCount); // need to add extraOptionsCount 
    });
  }

  onChangeFp(event){
    this.setState({
      nsId: event.target.value,
    });
  }
  getNetworkServerOption(id, callbackFunc) {
    NetworkServerStore.get(id, resp => {
      callbackFunc({label: resp.networkServer.name, value: resp.networkServer.id});
    });
  }

  getNetworkServerOptions(search, callbackFunc) {
    NetworkServerStore.list(0, 999, 0, resp => {
      const options = resp.result.map((ns, i) => {
        return {label: ns.name, value: ns.id}
      });
      callbackFunc(options);
    });
  }
  render() {
    const exportTypes = ["devices", "count_frames", "gateways", "users"]
    const selectItems = exportTypes.map(item => {
      return <MenuItem  key={getUuid()} value={item}>{item[0].toUpperCase() + item.substring(1)}</MenuItem>
    })

    return(
      <Fragment>
        <div className={this.props.classes.exportFormContainer}>
          <FormControl>
            <InputLabel htmlFor="export">Select objects to export</InputLabel>
              <Select
                value={this.state.exportValue}
                onChange={this.exportSelectOnChange}
                inputProps={{
                  name: "export",
                }}
                className={this.props.classes.selectExport}
                disabled={this.state.exportLoading}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {selectItems}
              </Select>
          </FormControl>
          <Button
            variant="outlined"
            color="default"
            onClick={this.exportFile}
            disabled={this.state.exportValue === '' || this.state.exportLoading || !this.formIsValid()}
            startIcon={<GetAppIcon />}
          >
            Export
          </Button>
        </div>
        {this.state.exportValue === 'devices' &&
          <div>
          <AutocompleteSelect
            id="exportOrganizationID"
            margin="none"
            label="Select organization"
            value={this.state.organization}
            onChange={this.organizationSelectOnChange}
            getOption={this.getOrganizationOption}
            getOptions={this.getOrganizationOptions}
            className={this.props.classes.selectOrganization}
            disabled={this.state.exportLoading}
            extraOptionsCount={0}
          />
          <AutocompleteSelect
            id="nsId"
            label="Select frequency plan"
            value={this.state.nsId || ""}
            onChange={this.onChangeFp}
            getOption={this.getNetworkServerOption}
            getOptions={this.getNetworkServerOptions}
            className={this.props.classes.selectNS}
          />
        </div>}

        {this.state.exportValue === 'count_frames' &&
          <div>
          <AutocompleteSelect
            id="exportOrganizationID"
            margin="none"
            label="Select organization"
            value={this.state.organizationId}
            onChange={(e)=>this.setState({organizationId: e.target.value})}
            getOption={this.getOrganizationOption}
            getOptions={this.getOrganizationOptions}
            className={this.props.classes.selectOrganization}
            disabled={this.state.exportLoading}
            extraOptionsCount={0}
          />
              <MuiPickersUtilsProvider  utils={DateFnsUtils} locale={ruLocale}>
                <KeyboardDateTimePicker
                variant="dialog"
                  ampm={false}
                  label="Date from ..."
                   value={this.state.start}
                  onChange={(value)=> this.setState({start: value})}
                  onError={console.log}
                  format="yyyy/MM/dd HH:mm"
                  className={this.props.classes.selectDateTime}
                  disabled={this.state.exportLoading}
                /><br/>
                <KeyboardDateTimePicker
                  variant="dialog"
                  ampm={false}
                  label="Date to ..."
                  value={this.state.end}
                  onChange={(value)=> this.setState({end: value})}
                  onError={console.log}
                  format="yyyy/MM/dd HH:mm"
                  className={this.props.classes.selectDateTime}
                  disabled={this.state.exportLoading}
                />
              </MuiPickersUtilsProvider>
          {/* <AutocompleteSelect
            id="nsId"
            label="Select frequency plan"
            value={this.state.nsId || ""}
            onChange={this.onChangeFp}
            getOption={this.getNetworkServerOption}
            getOptions={this.getNetworkServerOptions}
            className={this.props.classes.selectNS}
          /> */}
        </div>}


        {this.state.exportValue === 'gateways' &&
          <div>
          <AutocompleteSelect
            id="nsId"
            label="Select frequency plan"
            value={this.state.nsId || ""}
            onChange={this.onChangeFp}
            getOption={this.getNetworkServerOption}
            getOptions={this.getNetworkServerOptions}
            className={this.props.classes.selectNS}
          />
        </div>}        

        {this.state.exportLoading && this.state.exportPerc !== 0 &&
        <Box style={{
          width: '50px',
          height: '40px',
          position: 'fixed',
          top: '50%',
          left: '50%',
          zIndex: '1000'}}
        >
          <CircularProgress value={this.state.exportPerc} style={{width:'100%'}} color={'inherit'} variant={'determinate'} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="caption" style={{fontWeight:800,fontSize:'1rem'}} component="div" color="textSecondary">
              {!isNaN(this.state.exportPerc) ? `${this.state.exportPerc.toString()}%` : ''}
            </Typography>
          </Box>
        </Box>
        }
        {this.state.exportLoading && this.state.exportPerc === 0 &&
          <Box style={{
            width: '50px',
            height: '40px',
            position: 'fixed',
            top: '50%',
            left: '50%',
            zIndex: '1000'}}
          >
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          </Box>
        }

      </Fragment>

    );
  }
}

export default withRouter(withStyles(styles)(Export));
