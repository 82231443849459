import React from "react"

import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import TextField from "@material-ui/core/TextField"

import FormComponent from "../../../classes/FormComponent"
import Form from "../../../components/Form"
import { InputLabel, MenuItem, Select } from "@material-ui/core"

class MyDevicesIntegrationForm extends FormComponent {
  endpointChange = (e) => {
    let object = this.state.object
    if (e.target.value === "custom") {
      object.endpoint = ""
    } else {
      object.endpoint = e.target.value
    }
    this.setState({ object })
  }

  render() {
    if (this.state.object === undefined) {
      return null
    }

    let endpointSelect = "custom"
    if (this.state.object.endpoint === undefined) {
      endpointSelect = ""
    }

    const endpointOptions = [
      { value: "https://lora.mydevices.com/v1/networks/chirpstackio/uplink", label: "Cayenne" },
      { value: "https://lora.iotinabox.com/v1/networks/iotinabox.chirpstackio/uplink", label: "IoT in a Box" },
      { value: "custom", label: "Custom endpoint URL" },
    ]

    return (
      <Form submitLabel={this.props.submitLabel} onSubmit={this.onSubmit}>
        <FormControl required fullWidth>
          <InputLabel>myDevices endpoint</InputLabel>
          <Select value={endpointSelect} onChange={this.endpointChange}>
            {endpointOptions.map((item) => (
              <MenuItem id="_endpoint" key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {endpointSelect === "custom" && (
          <FormControl fullWidth margin="normal">
            <FormLabel>myDevices integration configuration</FormLabel>
            <TextField
              id="endpoint"
              label="myDevices API endpoint"
              placeholder="http://host:port"
              value={this.state.object.endpoint || ""}
              onChange={this.onChange}
              margin="normal"
              required
              fullWidth
            />
          </FormControl>
        )}
      </Form>
    )
  }
}

export default MyDevicesIntegrationForm
