import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton, InputAdornment, TextField, Tooltip } from "@material-ui/core"
import ClipboardTextMultipleOutline from "mdi-material-ui/ClipboardTextMultipleOutline"
import React from "react"

import VisibilityIcon from "@material-ui/icons/Visibility"

import FormComponent from "../../../../classes/FormComponent"
import { CopyHelper } from "../../../../classes/Helper"
import AutocompleteSelect from "../../../../components/AutocompleteSelect"
import Form from "../../../../components/Form"

class MQTTIntegrationForm extends FormComponent {
  constructor() {
    super()
    this.onChangePass = this.onChangePass.bind(this)
    this.copyToClipboard = this.copyToClipboard.bind(this)

    this.state = {
      passOpen: false,
    }
  }

  copyToClipboard() {
    CopyHelper({
      target: { innerText: this.state.object.password },
    })
  }

  getMarshalerOptions = (search, callbackFunc) => {
    const marshalerOptions = [
      { value: "JSON_SHRINKED", label: "JSON (general)" },
      { value: "JSON", label: "JSON (legacy)" },
      { value: "ACTILITY_JSON", label: "JSON Actility-Style (legacy)" },
      { value: "PROTOBUF", label: "Protocol Buffers" },
    ]
    callbackFunc(marshalerOptions)
  }

  onChangePass(e) {
    const { id, value } = e.target

    let event = {
      target: { id, value, type: "text" },
    }

    this.onChange(event)
    // if(!this.state.newPassClicked){
    //   this.setState({newPassClicked:true},()=>{
    //     this.onChange(event);
    //   })
    // }else{
    //   this.onChange(event);
    // }
  }

  render() {
    if (this.state.object === undefined) {
      return null
    }

    return (
      <Form submitLabel={this.props.submitLabel} autoComplete="off" onSubmit={this.onSubmit}>
        <FormControl fullWidth margin="normal">
          <FormLabel required>Payload marshaler</FormLabel>
          <AutocompleteSelect
            id="marshaler"
            label="Select payload marshaler"
            value={this.state.object.marshaler || ""}
            onChange={this.onChange}
            getOptions={this.getMarshalerOptions}
            required
          />
          <FormHelperText>This defines how the payload will be encoded.</FormHelperText>
        </FormControl>
        <TextField
          id="username"
          label="Username"
          value={this.state.object.username || ""}
          onChange={(e) => {
            const value = e.target.value
            this.setState((state) => (state.object.username = value.replace(/\s+/g, "")))
          }}
          margin="normal"
          fullWidth
          required
        />
        <TextField
          type={this.state.passOpen ? "text" : "password"}
          id="password"
          label="Password"
          value={this.state.object.password || ""}
          onChange={(e) => {
            const value = e.target.value
            this.setState((state) => (state.object.password = value.replace(/\s+/g, "")))
          }}
          margin="normal"
          autoComplete="off"
          fullWidth
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {this.state.object.password && (
                  <>
                    <Tooltip title="Watch password">
                      <IconButton
                        aria-label="Watch password"
                        onClick={() => {
                          this.setState({
                            passOpen: !this.state.passOpen,
                          })
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Copy password to clipboard">
                      <IconButton aria-label="Copy password to clipboard" onClick={this.copyToClipboard}>
                        <ClipboardTextMultipleOutline />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="server"
          label="Server"
          placeholder="tcp://localhost:1883"
          value={this.state.object.server || ""}
          onChange={(e) => {
            const value = e.target.value
            this.setState((state) => (state.object.server = value.replace(/\s+/g, "")))
          }}
          margin="normal"
          fullWidth
          required
        />
        <FormControlLabel
          control={<Checkbox id="advancedSettings" checked={this.state.object.advancedSettings} onChange={this.onChange} name="advanced" color="primary" />}
          label="Advanced"
        />
        {this.state.object.advancedSettings && (
          <>
            <TextField id="clientId" label="Client ID" value={this.state.object.clientId || ""} onChange={this.onChange} margin="normal" fullWidth />
            <TextField
              id="eventTopicTemplate"
              label="Event topic template"
              value={this.state.object.eventTopicTemplate || ""}
              onChange={this.onChange}
              margin="normal"
              fullWidth
            />
            <TextField
              id="commandTopicTemplate"
              label="Command topic template"
              value={this.state.object.commandTopicTemplate || ""}
              onChange={this.onChange}
              margin="normal"
              fullWidth
            />
          </>
        )}
      </Form>
    )
  }
}

export default MQTTIntegrationForm
