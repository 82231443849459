import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";

import { Map, Marker } from 'react-leaflet';

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import KVForm from "../../components/KVForm";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import NetworkServerStore from "../../stores/NetworkServerStore";
import GatewayProfileStore from "../../stores/GatewayProfileStore";
import ServiceProfileStore from "../../stores/ServiceProfileStore";
import LocationStore from "../../stores/LocationStore";
import MapTileLayer from "../../components/MapTileLayer";
import EUI64Field from "../../components/EUI64Field";
import AESKeyField from "../../components/AESKeyField";
import theme from "../../theme";
import DictionaryStore from "../../stores/DictionaryStore";


const boardStyles = {
  formLabel: {
    color: theme.palette.primary.main,
  },
  a: {
    color: theme.palette.primary.main,
  },
};


class GatewayBoardForm extends Component {
  constructor() {
    super();

    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onChange(e) {
    let board = this.props.board;
    const field = e.target.id;

    board[field] = e.target.value;
    this.props.onChange(board);
  }

  onDelete(e) {
    e.preventDefault();
    this.props.onDelete();
  }

  render() {
    return (
      <FormControl fullWidth margin="normal">
        <FormLabel className={this.props.classes.formLabel}>Board #{this.props.i} configuration (<a href="#delete"
                                                                                                    onClick={this.onDelete}
                                                                                                    className={this.props.classes.a}>delete</a>)</FormLabel>
        <EUI64Field
          id="fpgaID"
          label="FPGA ID"
          margin="normal"
          value={this.props.board.fpgaID || ""}
          onChange={this.onChange}
          helperText="The FPGA ID of the geoloc concentrator board. This is only available for v2 gateways with geolocation capabilities. (optional)"
          fullWidth
        />
        <AESKeyField
          id="fineTimestampKey"
          label="Fine-timestamp decryption key"
          margin="normal"
          value={this.props.board.fineTimestampKey || ""}
          onChange={this.onChange}
          helperText="The fine-timestamp AES decryption key. When set,   Network Server will decrypt the fine-timestamp. This is only available for v2 gateways with geolocation capabilities. (optional)"
          fullWidth
        />
      </FormControl>
    );
  }
}

GatewayBoardForm = withStyles(boardStyles)(GatewayBoardForm);


const styles = {
  mapLabel: {
    marginBottom: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
  },
  formLabel: {
    fontSize: 12,
  },
  textAreaNoMarginTop: {
    marginTop: '0',
  }
};

class GatewayForm extends FormComponent {
  constructor() {
    super();

    this.state = {
      mapZoom: 15,
      tab: 0,
      tags: [],
      metadata: [],
      object: {
        location: {
          latitude: '',
          longitude: '',
          altitude: ''
        }
      },
      errorLatitude: false,
      errorLongitude: false,
    };

    this.getNetworkServerOption = this.getNetworkServerOption.bind(this);
    this.getNetworkServerOptions = this.getNetworkServerOptions.bind(this);
    this.getGatewayProfileOption = this.getGatewayProfileOption.bind(this);
    this.getGatewayProfileOptions = this.getGatewayProfileOptions.bind(this);
    this.setCurrentPosition = this.setCurrentPosition.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.updateZoom = this.updateZoom.bind(this);
    this.addGatewayBoard = this.addGatewayBoard.bind(this);

    this.getOpStatusOption = this.getOpStatusOption.bind(this);
    this.getOpStatusOptions = this.getOpStatusOptions.bind(this);
    this.coordinatesHandleChange = this.coordinatesHandleChange.bind(this);

    this.regExpLat = /^(\+|-)?(?:90(?:(?:\.0{1,4})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,4})?))$/;
    this.regExpLon = /^(\+|-)?(?:180(?:(?:\.0{1,4})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,4})?))$/;

  }

  componentDidMount() {
    super.componentDidMount();
    this.setKVArrays(this.props.object || {});

    if (!this.props.update) {
      this.setCurrentPosition();
    }
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    if (prevProps.object !== this.props.object) {
      this.setKVArrays(this.props.object || {});
    }
  }

  onChange(e) {
    if (e.target.id === "networkServerID" && e.target.value !== this.state.object.networkServerID) {
      let object = this.state.object;
      object.gatewayProfileID = null;
      object.serviceProfileID = null;
      this.setState({
        object: object,
      });
    }

    super.onChange(e);
  }

  // coordinatesHandleChange(e) {
  //   const regExpLat = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,15})?))$/;
  //   const regExpLon = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,15})?))$/;

  //   let value = e.target.value.replace(/[^\d\.]/g, '');

  //   let state = this.state;
  //   if (e.target.id === "location.latitude") {
  //     if (value === '') {
  //       state.object.location.latitude = 0;
  //       state.errorLatitude = false
  //     } else {
  //       state.object.location.latitude = value;
  //       regExpLat.test(value)
  //         ? state.errorLatitude = false
  //         : state.errorLatitude = true;
  //     }
  //   }
  //   if (e.target.id === "location.longitude") {
  //     if (value === '') {
  //       state.object.location.longitude = 0;
  //       state.errorLongitude = false
  //     } else {
  //       state.object.location.longitude = value;
  //       regExpLon.test(value)
  //         ? state.errorLongitude = false
  //         : state.errorLongitude = true;
  //     }
  //   }
  //   this.setState(state);

  //   // super.onChange(e);
  // }
  
  // handleChangeLatitude(value)
  // {
  //   const regExpLat = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,7})?))$/;
  //   let errorLatitude = this.state.errorLatitude
  //   if (!isNaN(Number(value)))
  //   {
  //     this.setState(state => ({...state, object: { ...state.object, location: {...state.object.location, latitude : value} }, errorLatitude}))
  //     errorLatitude = !value || regExpLat.test(value) ? false : true;
  //   }
  // }

  // handleChangeLongitude(value)
  // {
  //   const regExpLon = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,7})?))$/;
  //   let errorLongitude = this.state.errorLongitude
  //   if (!isNaN(Number(value)))
  //   {
  //     this.setState(state => ({...state, object: { ...state.object, location: {...state.object.location, longitude : value} }, errorLongitude}))
  //     errorLongitude = !value || regExpLon.test(value) ? false : true;
  //   }
  // }

  coordinatesHandleChange(e) {
    // const regExpLat = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,4})?))$/;
    // const regExpLon = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,4})?))$/;

    let value = e.target.value;
    if (e.target.value.includes(',')) {
      const valueArr = e.target.value.split(',');
      if (e.target.name === 'latitude') {
        value = valueArr[0] ? valueArr[0] : 0;
      } 
      if (e.target.name === 'longitude') {
        value = valueArr[1] ? valueArr[1] : 0;
      }
    }

    let state = this.state;
    if (!state.object.location) {
      state.object.location = {
        longitude: '',
        latitude: '',
        altitude: ''
      }
    }
    if (e.target.id === "location.latitude") {
      if (value === '') {
        state.object.location.latitude = 0;
        state.errorLatitude = false;
      } else {
        state.object.location.latitude = value;
        if (this.regExpLat.test(value)) {
          state.errorLatitude = false;
        } else {
          state.errorLatitude = true;
          state.object.location.latitude = value;
        }
      }
    }
    if (e.target.id === "location.longitude") {
      if (value === '') {
        state.object.location.longitude = 0;
        state.errorLongitude = false;
      } else {
        state.object.location.longitude = value;
        if (this.regExpLon.test(value)) {
          state.errorLongitude = false
        } else {
          state.errorLongitude = true;
          state.object.location.longitude = value
        }
      }
    }
    if (e.target.id === "location.altitude") {
      if (value === '') {
        state.object.location.altitude = 0
      } else {
        state.object.location.altitude = value
      }
    }
    this.setState(state);

    // super.onChange(e);
  }

  setCurrentPosition(e) {
    if (e !== undefined) {
      e.preventDefault();
    }

    LocationStore.getLocation(position => {
      let object = this.state.object;
      object.location = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        altitude: position.coords.altitude,
      }
      this.setState({
        object: object,
      });
    });
  }

  updatePosition() {
    const position = this.refs.marker.leafletElement.getLatLng();
    let object = this.state.object;
    object.location = {
      ...object.location,
      latitude: position.lat.toFixed(4),
      longitude: position.lng.toFixed(4),
      // altitude: this.state.object.location.altitude,
    }
    this.setState({
      object: object,
    });
  }

  updateZoom(e) {
    this.setState({
      mapZoom: e.target.getZoom(),
    });
  }

  getOpStatusOption(val, callbackFunc) {
    if (this.options.length > 0) {
      callbackFunc(this.options.filter((el) => el.value === val)[0])
    }
  }

  getOpStatusOptions(search, callbackFunc) {

    DictionaryStore.getDictionary("operational_status", null, resp => {
      const options = resp.dict.map((d, i) => {
        return {label: d.label, value: d.code}
      })
      this.options = options;
      callbackFunc(options)
    })

  }

  getNetworkServerOption(id, callbackFunc) {
    NetworkServerStore.get(id, resp => {
      callbackFunc({label: resp.networkServer.name, value: resp.networkServer.id});
    });
  }

  getNetworkServerOptions(search, callbackFunc) {
    NetworkServerStore.list(this.props.match.params.organizationID, 999, 0, resp => {
      const options = resp.result.map((ns, i) => {
        return {label: ns.name, value: ns.id}
      });
      callbackFunc(options);
    });
  }

  getGatewayProfileOption(id, callbackFunc) {
    GatewayProfileStore.get(id, resp => {
      callbackFunc({label: resp.gatewayProfile.name, value: resp.gatewayProfile.id});
    });
  }

  getGatewayProfileOptions(search, callbackFunc) {
    if (this.state.object === undefined || this.state.object.networkServerID === undefined) {
      callbackFunc([]);
      return;
    }

    GatewayProfileStore.list(this.state.object.networkServerID, 999, 0, resp => {
      const options = resp.result.map((gp, i) => {
        return {label: gp.name, value: gp.id}
      });
      callbackFunc(options);
    });
  }

  getServiceProfileOption = (id, callbackFunc) => {
    ServiceProfileStore.get(id, resp => {
      callbackFunc({label: resp.serviceProfile.name, value: resp.serviceProfile.id});
    });
  }

  getServiceProfileOptions = (search, callbackFunc) => {
    if (this.state.object === undefined || this.state.object.networkServerID === undefined) {
      callbackFunc([]);
      return;
    }

    ServiceProfileStore.list(this.props.match.params.organizationID, this.state.object.networkServerID, 999, 0, resp => {
      const options = resp.result.map((sp, i) => {
        return {label: sp.name, value: sp.id}
      });
      callbackFunc(options);
    });
  }

  addGatewayBoard() {
    let object = this.state.object;
    if (object.boards === undefined) {
      object.boards = [{}];
    } else {
      object.boards.push({});
    }

    this.setState({
      object: object,
    });
  }

  deleteGatewayBoard(i) {
    let object = this.state.object;
    object.boards.splice(i, 1);
    this.setState({
      object: object,
    });
  }

  updateGatewayBoard(i, board) {
    let object = this.state.object;
    object.boards[i] = board;
    this.setState({
      object: object,
    });
  }

  onTabChange = (e, v) => {
    this.setState({
      tab: v,
    });
  }

  setKVArrays =  (props) => {
    let tags = [];
    let metadata = [];

    if (props.tags !== undefined) {
      for (let key in props.tags) {
        tags.push({key: key, value: props.tags[key]});
      }
    }

    if (props.metadata !== undefined) {
      for (let key in props.metadata) {
        metadata.push({key: key, value: props.metadata[key]});
      }
    }

    this.setState({
      tags: tags,
      metadata: metadata,
    });
  }

  render() {
    if (this.state.object === undefined) {
      return null;
    }

    const style = {
      height: 400,
    };

    let position = [];

    const latitude = this.state?.object?.location?.latitude
    const longitude = this.state?.object?.location?.longitude
    
    if (this.regExpLon.test(longitude) && this.regExpLat.test(latitude)) {
      position = [latitude, longitude];
    } else {
      position = [0, 0];
    }

    let boards = [];
    if (this.state.object.boards !== undefined) {
      boards = this.state.object.boards.map((b, i) => <GatewayBoardForm key={i} i={i} board={b}
                                                                        onDelete={() => this.deleteGatewayBoard(i)}
                                                                        onChange={board => this.updateGatewayBoard(i, board)}/>);
    }

    const tags = this.state.tags.map((obj, i) => <KVForm key={i} index={i} object={obj}
                                                         onChange={this.onChangeKV("tags")}
                                                         onDelete={this.onDeleteKV("tags")}/>);
    const metadata = this.state.metadata.map((obj, i) => <KVForm disabled={true} key={i} index={i} object={obj}
                                                                 onChange={this.onChangeKV("metadata")}
                                                                 onDelete={this.onDeleteKV("metadata")}/>);


    return (
      <Form
        submitLabel={this.props.submitLabel}
        disabled={this.state.errorLatitude || this.state.errorLongitude}
        onSubmit={this.onSubmit}
        extraButtons={this.state.tab === 0 && <Button onClick={this.addGatewayBoard}>Add board configuration</Button>}
      >
        <Tabs value={this.state.tab} onChange={this.onTabChange} indicatorColor="primary">
          <Tab label="General"/>
          <Tab label="Tags"/>
          <Tab label="Metadata"/>
        </Tabs>

        {this.state.tab === 0 && <div>
          <TextField
            id="name"
            label="Gateway name"
            margin="normal"
            value={this.state.object.name || ""}
            onChange={this.onChange}
            inputProps={{
              pattern: "[\\w-]+",
            }}
            helperText="The name may only contain words, numbers and dashes."
            required
            fullWidth
          />
          <TextField
            id="address"
            label="Gateway address"
            margin="normal"
            value={this.state.object.address || ""}
            onChange={this.onChange}
            rows={1}
            multiline
            fullWidth
            className={this.props.classes.textAreaNoMarginTop}
          />
          <TextField
            id="description"
            label="Gateway description"
            margin="normal"
            value={this.state.object.description || ""}
            onChange={this.onChange}
           
            multiline
            fullWidth
          />
          <EUI64Field
            disabled={this.props.update}
            id="id"
            label="Gateway ID"
            margin="normal"
            value={this.state.object.id || ""}
            onChange={this.onChange}
            required
            fullWidth
          />
          <TextField
            id="serno"
            label="Gateway serial number"
            margin="normal"
            value={this.state.object.serno || ""}
            onChange={this.onChange}
            inputProps={{
              pattern: "[\\w-]+",
            }}
            helperText=""
            required
            fullWidth
          />
          {this.props.update && <TextField
            id="phone"
            label="Gateway Mobile phone number"
            margin="normal"
            value={this.state.object.phone || ""}
            onChange={this.onChange}
            inputProps={{
              pattern: "[0-9]+",
            }}
            helperText=""
            fullWidth
          />}
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>Frequency plan</FormLabel>
            <AutocompleteSelect
              id="networkServerID"
              label="Select frequency plan"
              value={this.state.object.networkServerID || ""}
              disabled={!this.state.object.active&&this.state.object.active!==undefined}
              onChange={this.onChange}
              getOption={this.getNetworkServerOption}
              getOptions={this.getNetworkServerOptions}
              required
            />
            <FormHelperText>
              Select the frequency plan to which the gateway will connect. When no frequency plans are available in the
              dropdown, make sure a service-profile exists for this organization.
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormLabel required className={this.props.classes.formLabel}>Gateway-profile</FormLabel>
            <AutocompleteSelect
              id="gatewayProfileID"
              label="Select gateway-profile"
              value={this.state.object.gatewayProfileID || ""}
              triggerReload={this.state.object.networkServerID || ""}
              onChange={this.onChange}
              getOption={this.getGatewayProfileOption}
              getOptions={this.getGatewayProfileOptions}
              clearable={true}
              required
            />
            <FormHelperText>
              Optional. When assigning a gateway-profile to the gateway, Network Server will attempt to update the
              gateway according to the gateway-profile. Note that this does require a gateway with Concentratord.
            </FormHelperText>
          </FormControl>
          <TextField
            id="location.latitude"
            name="latitude"
            label="Gateway latitude (example - 59.9421)"
            margin="normal"
            type="text"
            error={this.state.errorLatitude}
            value={this.state.object?.location?.latitude || ""}
            onChange={this.coordinatesHandleChange}
            fullWidth
          />
          <TextField
            id="location.longitude"
            name="longitude"
            label="Gateway longitude (example - 30.3133)"
            margin="normal"
            type="text"
            error={this.state.errorLongitude}
            value={this.state.object?.location?.longitude || ""}
            onChange={this.coordinatesHandleChange}
            fullWidth
          />
          {/* <FormGroup>
            <FormControlLabel
              label="Gateway discovery enabled"
              control={
                <Checkbox
                  id="discoveryEnabled"
                  checked={!!this.state.object.discoveryEnabled}
                  onChange={this.onChange}
                  color="primary"
                />
              }
            />
            <FormHelperText> 
              When enabled (and   Network Server is configured with the gateway discover feature enabled), the gateway will send out periodical pings to test its coverage by other gateways in the same network.
            </FormHelperText>
          </FormGroup>*/}
          <TextField
            id="location.altitude"
            label="Gateway altitude (meters)"
            margin="normal"
            type="number"
            value={(this.state.object.location && this.state.object.location.altitude)?this.state.object.location.altitude : undefined}
            onChange={this.onChange}
            helperText="When the gateway has an on-board GPS, this value will be set automatically when the network has received statistics from the gateway."
            
            fullWidth
          />
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>Operational status</FormLabel>
            <AutocompleteSelect
              id="opStatusCode"
              label="Select operational status"
              value={this.state.object.opStatusCode || ""}
              onChange={this.onChange}
              getOption={this.getOpStatusOption}
              getOptions={this.getOpStatusOptions}
              required
            />
            <FormHelperText>
              {/* Description of autocomplete  */}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.mapLabel}>Gateway location</FormLabel>
            <Map
              center={position}
              zoom={this.state.mapZoom}
              style={style}
              animate={true}
              scrollWheelZoom={false}
              onZoomend={this.updateZoom}
            >
              <MapTileLayer/>
              <Marker position={position} draggable={true} onDragend={this.updatePosition} ref="marker"/>
            </Map>
            <FormHelperText>
              Drag the marker to the location of the gateway. When the gateway has an on-board GPS, this value will be
              set automatically when the network receives statistics from the gateway.
            </FormHelperText>
          </FormControl>
          {boards}
        </div>}
        {this.state.tab === 1 && <div>
          <FormControl fullWidth margin="normal">
            <Typography variant="body1">
              Tags can be used to store additional key/value data.
            </Typography>
            {tags}
          </FormControl>
          <Button variant="outlined" onClick={this.addKV("tags")}>Add tag</Button>
        </div>}
        {this.state.tab === 2 && <div>
          <FormControl fullWidth margin="normal">
            <Typography variant="body1">
              Metadata can be used by the Gateway Bridge to push information about the gateway (e.g. ip / hostname,
              serial number, temperatures, ...).
              This information is automatically updated when gateway statistics are received.
            </Typography>
            {metadata}
          </FormControl>
        </div>}
      </Form>
    );
  }
}

export default withStyles(styles)(GatewayForm);
