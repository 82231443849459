import { EventEmitter } from "events";

import Swagger from "swagger-client";
import RobustWebSocket from "robust-websocket";
import sessionStore from "./SessionStore";
import dispatcher from "../dispatcher";
import { checkStatus, errorHandler } from "./helpers";


class ExportStore extends EventEmitter {
  constructor() {
    super();
    this.dataExportCSV = this.dataExportCSV.bind(this);
    this.swagger = new Swagger("/swagger/dataexport.swagger.json",sessionStore.getClientOpts());
  }

  getListOfExport() {
    return Swagger.http({
      url: "/swagger/dataexport.swagger.json",
      method: 'GET',
      responseInterceptor: res => {
        return res;
      },
    });
  }

  getCountDeviceFrames(filter, callback) {
    this.swagger.then(client => {
      client.apis.DataExportService.DataExportService_CountDeviceFramesPerDevEui(filter)
        .then(checkStatus)
        .then(resp => {
          callback(resp.obj.result)})
        .catch((error) => {
          errorHandler(error)
          callback(null, error)
        });
    });
  }

  dataExportCSV(type, filter, onData, onClose, cb) {
    // filter.orgId = (sessionStore.isAdmin()) ? 0 :sessionStore.getOrganizationID();
    let serialize = function(obj, prefix) {
      var str = [],
        p;
      for (p in obj) {
        if (obj.hasOwnProperty(p)) {
          var k = prefix ? prefix + "[" + p + "]" : p,
            v = obj[p];
          str.push((v !== null && typeof v === "object") ?
            serialize(v, k) :
            encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
      }
      return str.join("&");
    }

    let queryString = serialize(filter);
    const loc = window.location;
    const wsURL = (() => {
      if (loc.host === "localhost:3000" || loc.host === "localhost:3001") {
        return `ws://localhost:8080/api/dataexport/${type}?${queryString}`;
      }

      const wsProtocol = loc.protocol === "https:" ? "wss:" : "ws:";
      return `${wsProtocol}//${loc.host}/api/dataexport/${type}?${queryString}`;
    })();

    const conn = new RobustWebSocket(wsURL,
      ["Bearer", sessionStore.getToken()],
      {
          shouldReconnect: function(event, ws){ return null },
      }
      );

    conn.addEventListener("open", () => {
      console.log('connected to', wsURL);
      this.wsDataStatus = "CONNECTED";
      this.emit("ws.status.change");
    });

    conn.addEventListener("message", (e) => {
      const msg = JSON.parse(e.data); // console.log( e );
      if (msg.error !== undefined) {
        dispatcher.dispatch({
          type: "CREATE_NOTIFICATION",
          notification: {
            type: "error",
            message: msg.error.message,
          },
        });
      } else if (msg.result !== undefined) {
        onData(msg.result);
      }
    });

    conn.addEventListener("close", () => {
      console.log('closing', wsURL);
      this.wsDataStatus = null;
      this.emit("ws.status.change");
      onClose(cb);
    });

    conn.addEventListener("error", (err) => {
      console.log("error", err);
      this.wsDataStatus = "ERROR";
      this.emit("ws.status.change");
    });

    return conn;
  }

  notify(object) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: `List of ${object} has been exported`,
      },
    });
  }
}

const exportStore = new ExportStore();
export default exportStore;
