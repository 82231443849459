import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React from "react";

import FormComponent from "../../../classes/FormComponent";
import Form from "../../../components/Form";


class AWSSNSIntegrationForm extends FormComponent {
  render() {
    if (this.state.object === undefined) {
      return null;
    }

    const marshalerOptions = [
      { value: "JSON", label: "JSON" },
      { value: "PROTOBUF", label: "Protocol Buffers" },
      { value: "JSON_V3", label: "JSON (legacy, will be deprecated)" },
    ]

    return (
      <Form submitLabel={this.props.submitLabel} onSubmit={this.onSubmit}>
        <FormControl required fullWidth>
          <InputLabel>Select payload marshaler</InputLabel>
          <Select
            value={this.state.object.marshaler}
            onChange={(e) => this.setState(state => {
              state.object.marshaler = e.target.value
              return state
            })
            }>
            {marshalerOptions.map((item) => <MenuItem id="marshaler" key={item.value} value={item.value}>{item.label}</MenuItem>)}
          </Select>
          <FormHelperText>This defines how the payload will be encoded.</FormHelperText>
        </FormControl>
        <TextField
          id="region"
          label="AWS region"
          value={this.state.object.region || ""}
          onChange={this.onChange}
          margin="normal"
          fullWidth
          required
        />
        <TextField
          id="accessKeyID"
          label="AWS Access Key ID"
          value={this.state.object.accessKeyID || ""}
          onChange={this.onChange}
          margin="normal"
          fullWidth
          required
        />
        <TextField
          id="secretAccessKey"
          label="AWS Secret Access Key"
          value={this.state.object.secretAccessKey || ""}
          onChange={this.onChange}
          margin="normal"
          type="password"
          fullWidth
          required
        />
        <TextField
          id="topicARN"
          label="AWS SNS topic ARN"
          value={this.state.object.topicARN || ""}
          onChange={this.onChange}
          margin="normal"
          fullWidth
          required
        />
      </Form>
    );
  }
}


export default AWSSNSIntegrationForm;
