import React from "react";

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import FormHelperText from "@material-ui/core/FormHelperText";

import FormComponent from "../../../classes/FormComponent";
import Form from "../../../components/Form";
import { InputLabel, MenuItem, Select } from "@material-ui/core";


class AzureServiceBusIntegrationForm extends FormComponent {
  render() {
    if (this.state.object === undefined) {
      return null;
    }

    const marshalerOptions = [
      {value: "JSON", label: "JSON"},
      {value: "PROTOBUF", label: "Protocol Buffers"},
      {value: "JSON_V3", label: "JSON (legacy, will be deprecated)"},
    ];

    return(
      <Form submitLabel={this.props.submitLabel} onSubmit={this.onSubmit}>
        <FormControl required fullWidth>
          <InputLabel>Select payload marshaler</InputLabel>
          <Select
            value={this.state.object.marshaler}
            onChange={(e) => this.setState(state => {
              state.object.marshaler = e.target.value
              return state
            })
            }>
            {marshalerOptions.map((item) => <MenuItem id="marshaler" key={item.value} value={item.value}>{item.label}</MenuItem>)}
          </Select>
          <FormHelperText>This defines how the payload will be encoded.</FormHelperText>
        </FormControl>
        <TextField
          id="connectionString"
          label="Azure Service-Bus connection string"
          value={this.state.object.connectionString || ""}
          onChange={this.onChange}
          margin="normal"
          helperText="This string can be obtained after creating a 'Shared access policy' with 'Send' permission."
          fullWidth
          required
        />
        <TextField
          id="publishName"
          label="Azure Service-Bus topic / queue name"
          value={this.state.object.publishName || ""}
          onChange={this.onChange}
          margin="normal"
          fullWidth
          required
        />
      </Form>
    );
  }
}

export default AzureServiceBusIntegrationForm;
