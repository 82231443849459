import React from "react";

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import FormHelperText from "@material-ui/core/FormHelperText";

import FormComponent from "../../../classes/FormComponent";
import Form from "../../../components/Form";
import { InputLabel, MenuItem, Select } from "@material-ui/core";


class GCPPubSubIntegrationForm extends FormComponent {

  render() {
    if (this.state.object === undefined) {
      return null;
    }

    const marshalerOptions = [
      { value: "JSON", label: "JSON" },
      { value: "PROTOBUF", label: "Protocol Buffers" },
      { value: "JSON_V3", label: "JSON (legacy, will be deprecated)" },
    ];

    return (
      <Form submitLabel={this.props.submitLabel} onSubmit={this.onSubmit}>
        <FormControl required fullWidth>
          <InputLabel>Select payload marshaler</InputLabel>
          <Select
            value={this.state.object.marshaler}
            onChange={(e) => this.setState(state => {
              state.object.marshaler = e.target.value
              return state
            })
            }>
            {marshalerOptions.map((item) => <MenuItem id="marshaler" key={item.value} value={item.value}>{item.label}</MenuItem>)}
          </Select>
          <FormHelperText>This defines how the payload will be encoded.</FormHelperText>
        </FormControl>
        <TextField
          id="projectID"
          label="GCP project ID"
          value={this.state.object.projectID || ""}
          onChange={this.onChange}
          margin="normal"
          fullWidth
          required
        />
        <TextField
          id="topicName"
          label="GCP Pub/Sub topic name"
          value={this.state.object.topicName || ""}
          onChange={this.onChange}
          margin="normal"
          fullWidth
          required
        />
        <TextField
          id="credentialsFile"
          label="GCP Service account credentials file"
          value={this.state.object.credentialsFile || ""}
          onChange={this.onChange}
          margin="normal"
          rows={10}
          helperText="Under IAM create a Service account with 'Pub/Sub Publisher' role, then put the content of the JSON key in this field."
          fullWidth
          multiline
          required
        />
      </Form>
    );
  }
}


export default GCPPubSubIntegrationForm;
