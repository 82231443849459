import React, { Component } from "react"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"

import ApplicationStore from "../../../../stores/ApplicationStore"
import MQTTIntegrationForm from "./MQTTIntegrationForm"

class UpdateMQTTIntegration extends Component {
  constructor() {
    super()

    this.state = {}
  }

  onSubmit = (integration) => {
    const { advancedSettings, ...integr } = integration
    integr.applicationID = this.props.match.params.applicationID
    if (!advancedSettings) {
      integr.commandTopicTemplate = ""
      integr.eventTopicTemplate = ""
      integr.clientId = ""
    }

    ApplicationStore.updateMQTTIntegration(integr, (resp) => {
      this.props.history.push(`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/integrations`)
    })
  }

  componentDidMount() {
    ApplicationStore.getMQTTIntegration(this.props.match.params.applicationID, (resp) => {
      const integration = resp.integration
      const advancedSettings = Boolean(integration?.commandTopicTemplate || integration?.eventTopicTemplate || integration?.clientId)
      this.setState({
        object: { ...integration, advancedSettings },
      })
    })
  }

  render() {
    if (this.state.object === undefined) {
      return null
    }

    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Update MQTT integration" />
            <CardContent>
              <MQTTIntegrationForm submitLabel="Update integration" object={this.state.object} onSubmit={this.onSubmit} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }
}

export default UpdateMQTTIntegration
